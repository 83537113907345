import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BookingService } from "src/app/services/booking.service";

@Component({
  selector: "app-error-message-dialog",
  template: `
    <div class="modal-header">
      <h3 class="modal-title">
        {{ "SLOT_ERROR_MESSAGE" | translate }}
      </h3>
    </div>
    <div class="modal-body">
      <div *ngIf="message === 'SLOT_CHANGE_DATA'">
        <div class="mb-2">{{ "SLOT_CHANGE_DATA" | translate }}</div>
        <div *ngIf="emailAddress">
          {{ ("SLOT_ERROR_EMAIL_ADDRESS" | translate) + ": " }}<b>{{ emailAddress }}</b>
        </div>
        <div *ngIf="phoneNumber">
          {{ ("SLOT_ERROR_PHONE_NUMBER" | translate) + ": " }}<b>{{ phoneNumber }}</b>
        </div>
      </div>
      <div *ngIf="message === 'SLOT_NO_AVAILABILITY'">
        {{ "SLOT_NO_AVAILABILITY" | translate }}
      </div>
      <div *ngIf="message === 'SLOT_ERROR_UNKNOWN'">
        {{ "SLOT_ERROR_UNKNOWN" | translate }}
      </div>
    </div>
    <div class="modal-footer justify-content-end p-0">
      <button type="button" class="btn btn-primary btn-50-mobile" aria-label="Close" (click)="activeModal.dismiss()">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
    </div>
  `,
})
export class ErrorMessageDialogComponent {
  @Input() message!: string;
  @Input() emailAddress: string | null | undefined;
  @Input() phoneNumber: string | null | undefined;
  @Input() date: string | null | undefined;

  constructor(public activeModal: NgbActiveModal, public bookingService: BookingService) {}

  delete = () => {
    this.activeModal.close(true);
  };
}
